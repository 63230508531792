@import '@uremont/erp-ui/build/scss/mixins';
@import './colors.module';

@mixin breakpoint-max-width($breakpoint) {
  @media (max-width: #{$breakpoint}) {
    @content;
  }
}

@mixin breakpoint-min-width($breakpoint) {
  @media (min-width: #{$breakpoint}) {
    @content;
  }
}

@mixin breakpoint-min-and-max-width($min, $max) {
  @media (min-width: #{$min}) and (max-width: #{$max}) {
    @content;
  }
}

@mixin dark-theme {
  background-color: var(--color-icon-dark);
  border-color: var(--color-box-shadow-color);
  color: var(--color-bg);
}

input {
  &[type='checkbox'] {
    + span {
      border: 1.5px solid var(--color-border-default);
      box-shadow: unset;

      --checkbox-border-color: var(--color-border-default);
    }

    &:checked {
      + span {
        border: 1.5px solid var(--color-brand);
      }
    }
  }
}

.dark {
  @include dark-theme;

  input {
    @include dark-theme;

    background-color: transparent;

    &[type='checkbox'] {
      + span {
        @include dark-theme;

        color: var(--color-icon-dark);
      }

      &:checked {
        + span {
          border: 1.5px solid var(--color-brand);

          --checkbox-border-color: var(--color-brand);
        }
      }
    }

    &[type='radio'] {
      &:checked {
        + span {
          &:hover {
            box-shadow: inset 0 0 0 0.375rem currentcolor;
          }
        }
      }

      + span {
        @include dark-theme;

        cursor: pointer;
        box-shadow: inset 0 0 0 0.125rem var(--color-box-shadow-color);

        &:hover {
          box-shadow: inset 0 0 0 0.125rem var(--color-bg);
        }
      }
    }

    &[type='number'] {
      @include dark-theme;
    }
  }

  textarea {
    @include dark-theme;
  }
}

@mixin dark-theme-container {
  body:global(.dark) {
    @content;
  }
}
