@import '../scss/mixins';
@import '../scss/breakpoints.module';
@import '@uremont/erp-ui/build/styles.css';
@import '../scss/sanitize';
@import '../scss/colors.module';

.slick-slide {
  z-index: 998;
}

.slick-active.slick-current {
  z-index: 999;
}
